
import { useStore } from 'vuex'
import { computed, reactive, ref, watch } from 'vue';
import { formatTimePlan_ } from '@/utils/utils';

export default {
	emits: ['hide', 'showTips'],
	name: 'FieldRedeemDialog',
	props: ["package", "username", "availablePoints"],
	setup(props, { emit }) {
		const store = useStore()
		const local = computed(() => store.state.translation.locale)
		let ownername = ref(props.username)
		const formatTimePlan = (obj, days, hours, minutes) => {
			return formatTimePlan_(obj, days, hours, minutes)
		}

		// Watch for changes and update ref
		watch(() => props.username, (newVal) => {
			ownername.value = newVal;
		});

		const data = reactive({
			errorMessage: ''
		})

		const validateOwner = () => {
			if (!ownername.value.length) {
				data.errorMessage = local.value.my_points_31  // owner empty
				return false
			}
			else if (ownername.value.length < 5) {
				data.errorMessage = local.value.my_points_32  // owner less than 5
				return false
			}
			else {
				data.errorMessage = ''
				return true
			}
		}

		const setOwner = (owner: string) => {
			ownername.value = owner
		}

		const hide_ = () => {
			emit('hide')
		}

		const limitInput = () => {
			if (ownername.value.length && (ownername.value.length > 16 || ownername.value.length < 5)) {
				data.errorMessage = local.value.my_points_32
				emit('showTips', data.errorMessage)
				ownername.value = ownername.value.slice(0, 16).toString()
			}
		}

		return {
			local,
			props,
			formatTimePlan,
			data,
			validateOwner,
			setOwner,
			hide_,
			ownername,
			limitInput
		}
	}
}
