

import {  reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import ShareImagePart from '@/components/share/ShareImagePart.vue';
import ShareUrlPart from '@/components/share/ShareUrlPart.vue';
import { websiteHost_ } from "@/config/config"

export default {
    name: 'ShareLink',
    components: {
        ShareImagePart,
        ShareUrlPart
    },

    setup() {
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        const route = useRoute()
        const router = useRouter()
        const websiteHost = computed(()=> websiteHost_)

        const state = reactive({
           
        })
        const data = reactive({
           code:route.query.code || "******",
           p:['url', 'image'].some((val) => route.query.p === val)? route.query.p : "url"
        })

        onMounted(async ()=>{
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang??'cn' })
        })

        function back() {
            router.back()
        }


        return {
            back,
            state,
            local,
            data,
            websiteHost
        }
    }
}
