
import { useStore } from 'vuex'
import { computed, reactive } from 'vue';

export default {
	emits: ['hide','change'],
	name: 'ChangeAccountDialog',
	props: [""],
	setup(props, { emit }) {
		const store = useStore()
		const local = computed(() => store.state.translation.locale)
		

		const data = reactive({
            method: ['alipay','usdt'],
		})

		const hide_ = () => {
			emit('hide')
		}

        const change_ = (method) => {
            emit('change',method)
        }

		return {
			local,
			props,
			data,
			hide_,
            change_
		}
	}
}
