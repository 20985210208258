
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'

export default {
    name: "WithdrawSubmitted",
    components: {
    },
    setup() {
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        const router = useRouter()
        const route = useRoute()
        const isEN = computed(() => route.query.lang?.toString().toLowerCase() == 'en')
        const data = reactive({
            encryptedToken: decodeURIComponent(route.query.id as any ?? ' ').replace(/\s/g, '+'),
        })

       onMounted(async ()=>{
        await store.dispatch(`translation/getLangs`, { userLang: route.query.lang??'cn' })
       })

        

        const BackToMyPoints = () => {
            router.back()
        }

        

        const convertDate = (date) => {
            return moment(date).format("YYYY-MM-DD HH:mm")
        }

        return {
            data,
            local,
            BackToMyPoints,
            convertDate,
            isEN,
        }
    }
}

