
import { useStore } from 'vuex'
import { computed, reactive } from 'vue';

export default {
    emits: ['hide', 'submit'],
    name: 'ConfirmDataDialog',
    props: ["amount", "account", "fullname", "address", "isAlipay"],
    setup(props, { emit }) {
        const store = useStore()
        const local = computed(() => store.state.translation.locale)

        const data = reactive({
            method: ['alipay', 'usdt'],
        })

        const hide_ = () => {
            emit('hide')
        }


        return {
            local,
            props,
            data,
            hide_,
        }
    }
}
