const share = {
    namespaced: true,
    state: {
        shareInfo: {
            status: null,
            message: '',
            inviteCode: '******',
            invitesShareCount: 0,
            availableSharePoints: 0,
            availablePurchasePoints: 0,
            pendingPurchasePoints: 0,
            availablePurchasePointsYuan: 0,
            redeemedTrafficPackages: [],
            yesterdayPoints: 0,
            withdrawnPoints:0,
            isSuccess: false,
            share_rebate:[{new_purchase_percent:0,renew_percent:0},{new_purchase_percent:0,renew_percent:0},{new_purchase_percent:0,renew_percent:0}],
            user_name:'',
            withdrawnYuan: 0,
            userAvailablePurchasePointsNoRefundNoPending: 0,
            userAvailablePurchasePointsNoRefundNoPendingYuan:0,
            share_gifted_points:0
        },
        systemPlans: [] as {
            id: -1,
            title: "",
            description: "",
            product_key: "",
            plan_category: -1,
            plan_time: 0,
            plan_time_obj:{
                days:0,
                hours:0,
                minutes:0
            }
            total_month: 0,
            billed: 0,
            status: -1,
            sorting_order: 0,
            bandwidth_limit: 0,
            extra_bandwidth: 0,
            speed_limit: "0",
            allowed_device_number: 2,
            is_recommended: 0,
            is_offer: 0,
            is_disabled: 0,
            price_coins: 0,
            price_coins_discount: 0
        }[],
        records:{
            status:false,
            message: '',
            isSuccess: false,
            UserPointsProcessesShare:[],
            UserPointsProcessesRedeemShare:[],
            UserPointsProcessesPurchase:[],
            UserPointsProcessesWithdraw:[],
            UserPointsProcessesRedeemPurchase:[],
            UserPointsProcessesPurchasePendding:[],
            UserPointsProcessesPurchaseRefunded:[],
            allProcess:[]
        },
        withdrawnHistory:{
            message: '',
            total_records: 0,
            isSuccess: false,
            data:[]
        }
        
    },
    mutations: {
        UPDATE_SHARE_INFO(state, data) {
            state.shareInfo = data
        },
        UPDATE_SYSTEM_PLANS(state,data) {
            state.systemPlans = data
        },
        UPDATE_RECORDS(state,data) {
            state.records = data
        },

        UPDATE_WITHDRAWN_HISTORY(state,data) {
            state.withdrawnHistory = data
        }
    },
    actions: {
        setShareInfo({ commit }, data) {
            commit('UPDATE_SHARE_INFO', data)
        },

        setSystemPlans({ commit }, data) {
            commit('UPDATE_SYSTEM_PLANS', data)
        },

        setRecords({commit}, data) {
            commit('UPDATE_RECORDS', data)
        },

        setWithdrawnHistory({commit}, data) {
            commit('UPDATE_WITHDRAWN_HISTORY',data)
        }
    }
}

export default share
