import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("iframe", {
      ref: "hiddenIframe",
      src: $setup.data.fixedRoute,
      class: "hidden-iframe"
    }, null, 8, _hoisted_1),
    ($setup.data.screenshotUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $setup.data.screenshotUrl,
          alt: "Captured Screenshot",
          class: "invisible-image"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}