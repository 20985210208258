

import { onMounted, onBeforeUnmount, reactive ,computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Fade from './../transitions/Fade.vue'

export default {
    name: "Footer",
    components: {
        Fade
    },
    setup() {
         
        const isVisible = ref(false);
        const isTooltipChatVisible = ref(true);
        const isTooltipChatVisible2 = ref(false);
        const isTooltipScrollVisible = ref(false); 
        const store = useStore()
        const locale = computed(() => store.state.translation.locale)

        const handleScroll = () => {
            isVisible.value = window.pageYOffset > 300;
            if (isTooltipScrollVisible.value && isVisible.value)
            isTooltipScrollVisible.value = false;
        };

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        const data = reactive({
            current_year: new Date().getFullYear(),
        })

        onMounted(() => {
         window.addEventListener('scroll', handleScroll);
       });

       onBeforeUnmount(() => {
         window.removeEventListener('scroll', handleScroll);
       });

       const showTooltip = (id:number) => {
        if (id==1)
        {
         isTooltipChatVisible2.value = true;
         isTooltipChatVisible.value = false;   
        }
        else
        if (id==2)
         isTooltipScrollVisible.value = true;
        /* else
         if (id==3)
        {
         isTooltipChatVisible2.value = true;
         isTooltipChatVisible.value = false;   
        } */
       };

       const hideTooltip = (id:number) => { 
        if (id==1)
        {
         isTooltipChatVisible2.value = false;
         isTooltipChatVisible.value = true;   
        }
        else
        if (id==2)
         isTooltipScrollVisible.value = false;
       /*  else
         if (id==3)
        {
         isTooltipChatVisible2.value = false;
         isTooltipChatVisible.value = true;   
        } */
       };

        

        return { 
            scrollToTop,
            isVisible,
            isTooltipChatVisible,
            isTooltipChatVisible2,
            isTooltipScrollVisible,
            showTooltip,
            hideTooltip,
            locale,
            data
        }
    }
}
