<template> 
  <Nav v-if="!$route.meta.showShare" />  
    <div class="container-fluid" v-if="!$route.meta.showShare">
      <div class="row flex-nowrap" v-if="auth">
        <div class="col-auto col-md-3 col-xl-2  px-0 bg-dark">
          <div class="d-flex flex-column align-items-center align-items-sm-start  text-white min-vh-100">
            
            <SideNav />
  
          </div>
        </div>
        <div class="col py-3">
          <router-view />
          <notifications position=" center" />
        </div>
      </div>
      
      <div class="row flex-nowrap" v-if="!auth">
        <router-view /> 
        <notifications position=" center" /> 
      </div>
    </div>
  <Footer v-if="!$route.meta.showShare" />  

   <!-- start share section  -->
   <router-view  v-if="$route.meta.showShare"/> 
   <notifications position=" center" v-if="$route.meta.showShare"/>
   <!-- end share section -->
  </template>
  
  <script>
  
  import { useStore } from "vuex";
  import { computed ,onMounted} from "vue";  
  import { saveVisitor } from "@/api/api"


  import "bootstrap/dist/css/bootstrap.min.css"
  import "bootstrap"
  import 'bootstrap-icons/font/bootstrap-icons.css'
  
  import 'aos/dist/aos.css';
  import "@/assets/css/style.css";
  import "@/assets/css/nav.css";
  import "@/assets/css/statistics.css";
  import Nav from "@/components/layout/Nav.vue"
  import SideNav from "@/components/layout/SideNav.vue"
  import Footer from "@/components/layout/Footer.vue"  
  import AOS from 'aos';
  import { useRoute } from 'vue-router'
  
  export default {
    components: {
      Nav,
      SideNav,
      Footer
    },
    setup() {
  
      const store = useStore();
      const auth = computed(() => store.state.authenticated);
      const route = useRoute()
      onMounted(()=>{
        // console.log("app channel detect=========",route.query?.channel)  
        // saveVisitor(route.query?.channel && typeof(route.query?.channel)!='undefined'?route.query.channel:null) 
      } 
      )
      

      AOS.init();
      return {
        auth,
      };
    }
  
  
  
  }
  </script>
  
  <style>
  i:hover {
    background-color: #ffc107;
    cursor: pointer;
  }
  
  </style>
  