
import { useStore } from 'vuex'
import { computed, reactive } from 'vue';

export default {
	emits: ['knew'],
	name: 'SuccessRedeemDialog',
	props: ["package","username"],
	setup(props, {emit}) {
		const store = useStore()
		const local = computed(() => store.state.translation.locale)
		

		const hide_ = () => {
			emit('knew')
		}

		return {
			local,
			props,
			hide_
		}
	}
}
