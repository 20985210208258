<template>
	<!-- 提示弹层 -->
	<transition name="fade">
		<div class="tips" v-show="tips.show">
			<p class="succ">{{ tips.title }}</p>
		</div>
	</transition>
</template>
<script>
import { defineComponent, reactive, watch } from 'vue';

export default defineComponent({
	name: 'Tips',
	setup(_, { expose }) {
		// Define reactive state
		const tips = reactive({
			show: false,
			title: '',
		});

		// Automatically hide tips after 3 seconds
		watch(() => tips.show, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					tips.show = false;
				}, 3000);
			}
		});

		// Expose a method to show the tips
		const showTips = (data) => {
			tips.title = data.title || '';
			tips.show = data.show || false;
		};

		// Expose `showTips` method to be accessed by parent
		expose({ showTips });

		return {
			tips,
		};
	},
});
</script>
<style scoped>
.tips{
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
}
.succ{
	padding: 8px 16px;
	font-size: 14px;
	/* background-color: #EDF0F5;
	color: #8E9196; */

	/* color: #4080FC;
	background-color: #dee9fe; */
	
	/* background-color: #16367c;
	color:#ffffff; */

	/* background-color: #b7ebcf; */
	/* color: #109627; */

	/* background-color: #edf0f5;
	color: #8E9196; */

	background-color: #00000079;
	color: #ffffff;

	border-radius: 4px;
	box-sizing: border-box;
	}
		


.fade-enter-active, .fade-leave-active {

	transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {

	opacity: 0;
}
</style>
