<template>
  <div class="dialog-referral-rewardas">
    <p class="title">{{ local.referral_rewards_title }}</p>
    <p class="des p-2 pb-3 pt-1">{{ local.referral_rewards_des }}</p>
    <div class="diagram">
      <div class="rectangle-1">
        <div class="triangle-left"></div>
        <!-- text-1-->
        <p class="p-text p-text-1">{{ local.referral_rewards_label_4 }}<br
            :style="{ display: local.referral_rewards_label_br }" />{{ local.referral_rewards_label_5 }}<br /><span
            style="color: #4080FC">{{shareRebate[2].new_purchase_percent}}%</span> <br />- <br /><span :class="[$route.query.lang?.toString().toLowerCase() == 'en' ? 'ml-en' : '']">{{ local.referral_rewards_label_6 }}</span><br
            :style="{ display: local.referral_rewards_label_br }" />{{ local.referral_rewards_label_7 }}<br /><span
            style="color: #4080FC">{{shareRebate[2].renew_percent}}%</span></p>
        <div class="rectangle-2">
          <!-- text-2 -->
          <p class="p-text p-text-2">{{ local.referral_rewards_label_4 }}<br
              :style="{ display: local.referral_rewards_label_br }" />{{ local.referral_rewards_label_5 }} <br /><span
              style="color: #4080FC">{{shareRebate[1].new_purchase_percent}}%</span> <br />- <br/> <span :class="[$route.query.lang?.toString().toLowerCase() == 'en' ? 'ml-en' : '']">{{ local.referral_rewards_label_6 }}</span><br
              :style="{ display: local.referral_rewards_label_br }"/>{{ local.referral_rewards_label_7 }} <br /><span
              style="color: #4080FC">{{shareRebate[1].renew_percent}}%</span></p>
          <div class="rectangle-3">
            <!-- text-3 -->
            <p class="p-text p-text-3">{{ local.referral_rewards_label_4 }}<br
                :style="{ display: local.referral_rewards_label_br }" />{{ local.referral_rewards_label_5 }} <br /><span
                style="color: #4080FC">{{shareRebate[0].new_purchase_percent}}%</span> <br />- <br /><span :class="[$route.query.lang?.toString().toLowerCase() == 'en' ? 'ml-en' : '']">{{ local.referral_rewards_label_6 }}</span><br
                :style="{ display: local.referral_rewards_label_br }" />{{ local.referral_rewards_label_7 }} <br /><span
                style="color: #4080FC">{{shareRebate[0].renew_percent}}%</span></p>
          </div>
        </div>
      </div>
      <!-- You -->
      <div class="card-level card-1">
        <img src="@/assets/images/mobile/single-user.svg" alt="single-user-icon" />
        <div class="card-des">
          {{ local.referral_rewards_card_1 }}
        </div>
      </div>
      <!-- NUS A -->
      <div class="card-level card-2">
        <img src="@/assets/images/mobile/users-group-1.svg" alt="group-users-icon" />
        <div class="card-des">
          {{ local.referral_rewards_card_2 }}
          <p>{{ local.referral_rewards_level_1 }}</p>
        </div>
      </div>
      <!-- NUS B -->
      <div class="card-level card-3">
        <img src="@/assets/images/mobile/users-group-1.svg" alt="group-users-icon" />
        <div class="card-des">
          {{ local.referral_rewards_card_3 }}
          <p>{{ local.referral_rewards_level_2 }}</p>
        </div>
      </div>
      <!-- NUS C -->
      <div class="card-level card-4">
        <img src="@/assets/images/mobile/users-group-1.svg" alt="group-users-icon" />
        <div class="card-des">
          {{ local.referral_rewards_card_4 }}
          <p>{{ local.referral_rewards_level_3 }}</p>
        </div>
      </div>
      <p class="label label-1">{{ local.referral_rewards_label_1 }}</p>
      <p class="label label-2">{{ local.referral_rewards_label_2 }}</p>
      <p class="label label-3">{{ local.referral_rewards_label_3 }}</p>
      <p class="arrow arrow-1"></p>
      <p class="arrow arrow-2"></p>
      <p class="arrow arrow-3"></p>
    </div>
    <!-- <div class="definition" :style="{ display: local.referral_rewards_definition_show }">
      <i class="bi bi-circle-fill"></i> -->
      <p class="definition">
        {{ local.referral_rewards_definition }}
      </p>
    <!-- </div> -->
    <div class="close-icon" @click="$emit('hide')">
      <!-- Inline SVG for Close Icon -->
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4522_11364)">
          <path
            d="M14 1.08424L12.9158 0L7 5.93478L1.08424 0L0 1.08424L5.93478 7L0 12.9158L1.08424 14L7 8.08424L12.9158 14L14 12.9158L8.08424 7L14 1.08424Z"
            fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_4522_11364">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
// import popupMixin from 'common/mixins/popup'

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex'

export default {
  name: 'DialogReferralRewards',
  // mixins: [popupMixin],
  setup() {
    const store = useStore()
    const local = computed(() => store.state.translation.locale)
    const shareRebate = computed(() => store.state.share.shareInfo.share_rebate)
    return {
      local,
      shareRebate
    }
  }
}
</script>

<style scoped>
.dialog-referral-rewardas {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 95%;
  max-width: 340px;
  min-width: 300px;
  height: 575px;
  box-sizing: content-box;
  font-size: 16px;
  text-align: center;
  background: #ffffff;
  border-radius: 8px;
}

.title {
  margin: 40px 0 15px;
  font-family: 'PingFang SC';
  color: #000000;
  font-size: 18px;
}

.des {
  /* margin-bottom: 2em; */
  font-size: 12px;
  color: #666666;
}

.diagram {
  position: relative;
  background-color: transparent;
  width: 17em;
  height: 400px;
  margin: auto;
}

.rectangle-1 {
  position: absolute;
  height: 344px;
  width: 141px;
  right: 0%;
  top: 0.68em;
  background-color: trasnparent;
  border: 1px solid #A1AABC;
  border-left: none;
}

.triangle-left {
  position: absolute;
  top: -4.5px;
  left: 0px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 8px solid #A1AABC;
}

.rectangle-2 {
  position: absolute;
  width: 94px;
  height: 242px;
  left: 0%;
  top: 0%;
  background-color: trasnperant;
  border-bottom: 1px solid #A1AABC;
  border-right: 1px solid #A1AABC;
}

.rectangle-3 {
  width: 47px;
  height: 139px;
  left: 0%;
  top: 0%;
  background-color: trasnparent;
  border-bottom: 1px solid #A1AABC;
  border-right: 1px solid #A1AABC;
}

.card-level {
  display: flex;
  position: absolute;
  left: -3%;
  width: 137px;
  height: 55px;
  background-color: #4080FC;
  /* border: 1px solid #A1AABC; */
  border-radius: 4px;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

.card-des {
  color: #FFFFFF;
  text-align: left;
  margin-left: 12px;
  font-size: 16px;
}

.card-des p {
  color: #CBDDFF;
  font-size: 12px;
  margin: 0;
}

.card-1 {
  top: -3.9%;
}

.card-2 {
  top: 30.5%;
}

.card-3 {
  top: 56.5%;
}

.card-4 {
  top: 82%;
}

.arrow {
  position: absolute;
  z-index: -2;
  width: 0;
  height: 0;
  left: 22%;
  background-color: #8B98B1;
}

.arrow:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #8B98B1;
  bottom: 0em;
  left: 50%;
  transform: translate(-50%, 0%);
}

.arrow-1 {
  padding: 2.4em 0.03em;
  top: 10.5%;
}

.arrow-2 {
  padding: 1.2em 0.03em;
  top: 46%;
}

.arrow-3 {
  padding: 1.2em 0.03em;
  top: 71.5%;
}

.label {
  color: #333333;
  font-size: 12px;
  text-align: center;
  position: absolute;
  background-color: #ffffff;
  left: -3%;
  width: 137px;
}

.label-1 {
  top: 18%;
}

.label-2 {
  top: 48%;
}

.label-3 {
  top: 73.5%;
}

.definition {
  /* display: flex; */
  /* margin: auto; */
  /* align-items: center; */
  /* width: 24em; */
  /* color: #FFFFFF; */
  font-size: 12px;
  /* padding: 0em 2.2em; */
  margin-top: -6px;
  padding: 2px 0;
  color: #333333;
  /* padding-left: 26px; */
  /* text-align: left; */
}

.definition p {
  text-align: left;
}

.definition i {
  font-size: 0.1em;
  padding-right: 5px;
  padding-top: 2px;
}

.p-text {
  position: absolute;
  color: #333333;
  text-align: center;
  width: 3.2em;
  font-size: 12px;
  line-height: 1.5em;
  margin-right: 1em;
}

.p-text-1 {
  width: 2.8em;
  top: 17.5em;
  left: 8.5em;
}

.p-text-2 {
  top: 9em;
  left: 4.5em;
  width: 2.8em;
}

.p-text-3 {
  left: 0.5em;
  top: 0.5em;
}


.close-icon {
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2%;
  left: 92%;
}

.ml-en{
  margin-left: -10px;
}

</style>
