
import { onMounted, reactive, computed, ref, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { callApi } from '@/api/api'
import Spinner from '@/components/transitions/Spinner.vue'
import MaskLayer from '@/components/share/MaskLayer.vue'
import moment from 'moment'
import Fade from '@/components/transitions/Fade.vue'

export default {
    name: "PointsDetails",
    components: {
        Spinner,
        MaskLayer,
        Fade
    },
    setup() {
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        const cards = computed(() => store.state.share.records)
        const userAvailablePurchasePointsNoRefundNoPending = computed(()=> store.state.share.shareInfo.userAvailablePurchasePointsNoRefundNoPending)
        const userAvailablePurchasePointsNoRefundNoPendingYuan = computed(()=> store.state.share.shareInfo.userAvailablePurchasePointsNoRefundNoPendingYuan)
        const namesBtnArr = computed(() => store.state.translation.locale.points_details_btn_arr )
        const isCredited = computed(()=> route.query.state_points === 'rebat')
        const namesFilterDate = computed(() => store.state.translation.locale.points_details_filter_arr)
        const router = useRouter()
        const route = useRoute()
        const listRef = ref(null);
        const data = reactive({
            namesBtnArr: store.state.translation.locale.points_details_btn_arr,
            valuesBtnArr: ['all', 'credited', 'pending', 'spent', 'deducted'],
            namesFilterDate: store.state.translation.locale.points_details_filter_arr,
            valuesFilterDate: [3, 6, 12, 24],
            currentFilterVal: route.query.lang?.toString().toLowerCase() == 'en'? 'Last 3 months' : '最近三个月' ,
            encryptedToken:decodeURIComponent(route.query.id as any ?? ' ').replace(/\s/g, '+').replace(/%2B/g,'+'),
            filteredCards: [] as any[],
            // sumOfCreditedPoints : 0
        })

        const state = reactive({
            currentBtnVal: 'all',
            showFilter: false,
            filterDateVal: 3,
            showLoading: false
        })


        onMounted(async () => {
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang??'cn' })
            openComponent('showLoading')
            if (data.encryptedToken) {
                // console.log("token=== ", data.encryptedToken)
                const records = await callApi('/user/points/processes', 'GET', data.encryptedToken as any).finally(() => closeComponent('showLoading'))
                if (records.isSuccess) {
                    await store.dispatch('share/setRecords', records)
                    // console.log(records)
                } else {
                    await store.dispatch('share/setRecords', {
                        status: false,
                        message: '',
                        isSuccess: false,
                        UserPointsProcessesShare: [],
                        UserPointsProcessesRedeemShare: [],
                        UserPointsProcessesPurchase: [],
                        UserPointsProcessesWithdraw: [],
                        UserPointsProcessesRedeemPurchase: [],
                        UserPointsProcessesPurchasePendding:[],
                        UserPointsProcessesPurchaseRefunded:[],
                        allProcess: []
                    })

                    router.push(
                        {
                            path: '/error',
                            query: { lang: route.query.lang??'cn' }
                        }
                    )
                }
            } else {
                router.push(
                        {
                            path: '/error',
                            query: { lang: route.query.lang??'cn' }
                        }
                    )
            }

            if (route.query.state_points == 'pending')
                selectVal('pending', 2, 'type')
            else if (route.query.state_points == 'rebat')
                selectVal('rebat',-1,'type')
            else
                filterCards()
            document.addEventListener("click", handleClickOutside);
        });
        onUnmounted(() => {
            document.removeEventListener("click", handleClickOutside);
        });
        const selectVal = (item: any, index: number, filter: string) => {
            if (filter == 'type')
                state.currentBtnVal = item
            else if (filter == 'date') {
                state.filterDateVal = item
                data.currentFilterVal = namesFilterDate.value[index]
            }

            filterCards(state.currentBtnVal,state.filterDateVal)
        }

        function toggleComponent(name: string) {
            if (name in state) {
                state[name] = !state[name]
            }
        }

        const handleClickOutside = (event) => {
            if (listRef.value && state.showFilter && !(listRef.value as any).contains(event.target)) {
                state.showFilter = false;
            }
        }

        const BackToMyPoints = () => {
            router.back()
        }

        const closeComponent = (name: string) => {
            if (name in state) {
                state[name] = false
            }
        }

        const openComponent = (name: string) => {
            if (name in state) {
                state[name] = true
            }
        }

        const filterCards = (type = 'all', month = 3) => {
            // fitering as type:
            // console.log('type==',type)
            // console.log('month==',month)
            // console.log(cards.value)
            let arr:any[] = []
            if (type === 'all') {
                arr = [
                ...cards.value.UserPointsProcessesShare.map(element => {return {...element,state:'',type:selectType(element.user_level,'share',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesRedeemShare.map(element => {return {...element,state:namesBtnArr.value[3],type:selectType(element.user_level,'redeem-share',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesPurchase.map(element => {return {...element,state:namesBtnArr.value[1],type:selectType(element.user_level,'purchase',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesWithdraw.map(element => {return {...element,state:namesBtnArr.value[6],type:selectType(element.user_level,'withdraw',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesRedeemPurchase.map(element => {return {...element,state:namesBtnArr.value[3],type:selectType(element.user_level,'redeem-purchase',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesPurchasePendding.map(element => {return {...element,state:namesBtnArr.value[2],type:selectType(element.user_level,'pending',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesPurchaseRefunded.map(element => {return {...element,points:0*element.points,state:namesBtnArr.value[8],type:selectType(element.user_level,'deducted',element.first_purchase)}}),
                ...cards.value.allProcess.map(element => {return {...element,state:namesBtnArr.value[5],type:selectType(element.user_level,'added',element.first_purchase)}}).filter(e=>e.point_type === 0 && e.remark.length && e.points>=0),
                ...cards.value.allProcess.map(element => {return {...element,state:namesBtnArr.value[7],type:selectType(element.user_level,'reclaimed',element.first_purchase)}}).filter(e=>e.point_type === 7 && e.remark.length && e.points<0)
                ]
            }
            else if(type === 'credited') {
                arr = [
                    ...cards.value.UserPointsProcessesShare.map(element => {return {...element,state:'',type:selectType(element.user_level,'share',element.first_purchase)}}),
                    ...cards.value.UserPointsProcessesPurchase.map(element => {return {...element,state:namesBtnArr.value[1],type:selectType(element.user_level,'purchase',element.first_purchase)}}),
                    ...cards.value.allProcess.map(element => {return {...element,state:namesBtnArr.value[5],type:selectType(element.user_level,'added',element.first_purchase)}}).filter(e=>e.point_type === 0 && e.remark.length && e.points>=0)
                ]

                
            } 
            else if(type === 'spent') {
                arr = [
                    ...cards.value.UserPointsProcessesRedeemShare.map(element => {return {...element,state:namesBtnArr.value[3],type:selectType(element.user_level,'redeem-share',element.first_purchase)}}),
                    ...cards.value.UserPointsProcessesWithdraw.map(element => {return {...element,state:namesBtnArr.value[6],type:selectType(element.user_level,'withdraw',element.first_purchase)}}),
                    ...cards.value.UserPointsProcessesRedeemPurchase.map(element => {return {...element,state:namesBtnArr.value[3],type:selectType(element.user_level,'redeem-purchase',element.first_purchase)}}),
                ]
            } 
            else if(type === 'pending' ) {
                arr = [...cards.value.UserPointsProcessesPurchasePendding.map(element => {return {...element,state:namesBtnArr.value[2],type:selectType(element.user_level,'pending',element.first_purchase)}})]
            }else if (type === 'rebat') {
                arr = [ 
                // ...cards.value.UserPointsProcessesShare.map(element => {return {...element,state:'',type:selectType(element.user_level,'share',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesPurchase.map(element => {return {...element,state:namesBtnArr.value[1],type:selectType(element.user_level,'purchase',element.first_purchase)}}),
                ...cards.value.UserPointsProcessesPurchaseRefunded.map(element => {return {...element,points:0*element.points,state:namesBtnArr.value[8],type:selectType(element.user_level,'deducted',element.first_purchase)}}),
                ]
                // data.sumOfCreditedPoints = arr.reduce((total,item)=>{return total + item.points},0)
            } else {
                arr = [
                    ...cards.value.UserPointsProcessesPurchaseRefunded.map(element => {return {...element,points:0*element.points,state:namesBtnArr.value[8],type:selectType(element.user_level,'deducted',element.first_purchase)}}),
                    ...cards.value.allProcess.map(element => {return {...element,state:namesBtnArr.value[7],type:selectType(element.user_level,'reclaimed',element.first_purchase)}}).filter(e=>e.point_type === 7 && e.remark.length && e.points<0)
                ]
            }

            data.filteredCards = arr.filter((item)=> month >= item.months_category)
            .sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) 

            // console.log(data.filteredCards)
        }

        const convertDate = (date) => {
            return moment(date).format("YYYY-MM-DD HH:mm")
        }

        const selectType = (level,type,first_purchase) => {
            if(level === 1) {
                if((type === 'purchase' || type === 'pending') && first_purchase) return local.value.purchase_level_1
                else if((type === 'purchase' || type === 'pending') && !first_purchase) return local.value.renwal_level_1
                else if(type === 'deducted') return local.value.deducted_level_1
            }else if(level === 2) {
                if((type === 'purchase' || type === 'pending') && first_purchase) return local.value.purchase_level_2
                else if((type === 'purchase' || type === 'pending') && !first_purchase) return local.value.renwal_level_2
                else if(type === 'deducted') return local.value.deducted_level_2
            }else if(level === 3) {  
                if((type === 'purchase' || type === 'pending') && first_purchase) return local.value.purchase_level_3
                else if((type === 'purchase' || type === 'pending') && !first_purchase) return local.value.renwal_level_3
                else if(type === 'deducted') return local.value.deducted_level_3
            }else if(type === 'share') {
                return local.value.share_earned
            }else if(type === 'redeem-purchase') {
                return local.value.redeem_package
            }else if(type === 'redeem-share') {
                return local.value.redeem_package
            }else if(type === 'withdraw') {
                return local.value.withdraw
            }else if(type === 'added' || type === 'reclaimed') {
                return local.value.test_points
            } else {
                return ''
            }
        }

        return {
            data,
            state,
            selectVal,
            toggleComponent,
            listRef,
            local,
            BackToMyPoints,
            closeComponent,
            openComponent,
            filterCards,
            convertDate,
            namesBtnArr,
            namesFilterDate,
            isCredited,
            userAvailablePurchasePointsNoRefundNoPending,
            userAvailablePurchasePointsNoRefundNoPendingYuan
        }
    }
}

