<template>
	<div class="mask-layer" :style="{ background: 'rgba(0,0,0,' + opacity + ')', 'z-index': zIndex }">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'MaskLayer',
	props: {
		opacity: { // 组件透明度
			type: Number,
			default: () => {
				return 0.7
			}
		},
		zIndex: { // 组件层级
			type: Number,
			default: () => {
				return 99
			}
		}
	}
}
</script>

<style scoped>
.mask-layer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* background: rgba(0, 0, 0, 0.7) */
}
	
</style>
