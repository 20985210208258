
import { computed, onMounted, reactive } from 'vue'
import QRCode from 'qrcodejs2'
import { useStore } from 'vuex'
// import { detectClientOS } from "@/api/api"
// import { getChannelNameScriptSRC } from '@/config/channels'
import { websiteHost_ } from '@/config/config'
import { useRoute } from 'vue-router'

export default {
    name: 'ShareImagePart',
    components: {

    },
    setup() {
        const route = useRoute()
        const store = useStore()
        const local = computed(()=> store.state.translation.locale)
        // const downloads = computed(() => store.state.channel.downloads)
        const link = computed(()=> `${websiteHost_}/share_link?code=${store.state.share.shareInfo.inviteCode}` )

        
        const data = reactive({
            loading: false,
            clientOS: '',
            clientType: '',
            clientOSImage: '',
            clientOSLink: '',
        })
        
        onMounted(async () => {
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang??'cn' })
            createQRCode(link.value)
            
            // console.log(`new version 2 of website===================`);
            // data.loading = true
            // //let lang = authUser.value.preferred_language ? authUser.value.preferred_language : "en";
            // //store.dispatch(`translation/getLangs`, { userLang: lang });
            // //let resData = await callApi(data.endpoint, 'GET')
            // //data.records = resData.data
            // await store.dispatch(`channel/setChannel`, {});
            // console.log("Set Channel Dispatched=====================", downloads)
            
            // await detectClientOS(data, local, downloads).then((result) => {
            //     data.clientOSLink = result.clientOSLink;
            //     data.clientOSImage = result.clientOSImage;
            //     data.clientOS = result.clientOS;
            //     data.clientType = result.clientType;
            // })
            // // Dynamically inject the script when this component is mounted
            // var _hmt = _hmt || [];
            // (function () {
            //     var hm = document.createElement("script");
            //     hm.src = getChannelNameScriptSRC();
            //     var s = document.getElementsByTagName("script")[0];
            //     s.parentNode?.insertBefore(hm, s);
            // })();
            
            // data.loading = false
            // createQRCode(data.clientOSLink)

        })


        function createQRCode(link: string) {
            const qrcode = new QRCode('qrcode', {
                width: 188, 
                height: 188,
            })
            const updatedLink = link.includes('?') ? `${link}&p=url&lang=${route.query.lang}` : `${link}?p=url&lang=${route.query.lang}`
            qrcode.makeCode(updatedLink)
            // qrcode.makeCode(link)
        }

        // const data = reactive({
        //     downloadAppLink : websiteHost.value+'/tutorials/android?d=y&p=2'
        // })

        return {
            createQRCode,
            data,
            local,
        }
    }
}
