

import { onMounted, reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import QRCode from 'qrcodejs2'
import { copyToClipboardUsingTextarea } from '@/utils/utils'
import Tips from '@/components/share/Tips.vue'
import MaskLayer from '@/components/share/MaskLayer.vue'
import DialogShareTips from '@/components/share/DialogShareTips.vue'
import Fade from '@/components/transitions/Fade.vue'
import DialogReferralRewards from '@/components/share/DialogReferralRewards.vue'
import { websiteHost_ } from '@/config/config'
import ScreenShot from '@/components/share/ScreenShot.vue'
import { callApi } from '@/api/api'
import Spinner from '@/components/transitions/Spinner.vue';
// import { apiHost } from '@/config/config'


export default {
    name: 'Share',
    components: {
        Tips,
        MaskLayer,
        DialogShareTips,
        Fade,
        DialogReferralRewards,
        ScreenShot,
        Spinner
    },

    setup() {
        onMounted(async () => {
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang })
            createQRCode(link.value)
            openComponent('showLoading')
            if (data.encryptedToken) {
                 //console.log("share query ==========",route.query)
                // console.log("token=== ", data.encryptedToken)
                let resData = await callApi(`/user/points`, 'GET', data.encryptedToken as any).finally(()=>closeComponent('showLoading'))
                if (resData.isSuccess) {
                    await store.dispatch('share/setShareInfo', resData)
                    // console.log(resData)
                } else {
                    await store.dispatch('share/setShareInfo', {
                        status: null,
                        message: '',
                        inviteCode: '******',
                        invitesShareCount: 0,
                        availableSharePoints: 0,
                        availablePurchasePoints: 0,
                        pendingPurchasePoints: 0,
                        availablePurchasePointsYuan: 0,
                        redeemedTrafficPackages: [],
                        yesterdayPoints: 0,
                        withdrawnPoints: 0,
                        isSuccess: false,
                        share_rebate:[{new_purchase_percent:0,renew_percent:0},{new_purchase_percent:0,renew_percent:0},{new_purchase_percent:0,renew_percent:0}],
                        user_name:'',
                        withdrawnYuan: 0,
                        userAvailablePurchasePointsNoRefundNoPending: 0,
                        userAvailablePurchasePointsNoRefundNoPendingYuan:0,
                        share_gifted_points:0
                    })

                    router.push(
                        {
                            path: '/error',
                            query: { lang: route.query.lang??'cn' }
                        }
                    )
                }
            } else {
                router.push(
                        {
                            path: '/error',
                            query: { lang: route.query.lang??'cn' }
                        }
                    )
            }
        })

        type TipsInstance = {
            showTips: (data: { show: boolean; title: string }) => void;
        };

        type ScreenShot = {
            captureScreenshot: (code: string) => void;
        }

        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        const shareInfo = computed(() => store.state.share.shareInfo)
        const link = computed(()=> `${websiteHost_}/share_link?code=${store.state.share.shareInfo.inviteCode}` )
        const host = computed(() => window.location.host)
        const route = useRoute()
        const router = useRouter()
        const textToCopy = ref<HTMLElement | null>(null);
        const tipsRef = ref<TipsInstance | null>(null);
        const screenShotRef = ref<ScreenShot | null>(null);
        // const encryptedToken = ref("erfwS4j1Gby5u8IkDklubG02UafmRtfsKFkfl/wFTiB3mDTl0WmsWYWyc0Do2J8iCJUAbgpAlpTVWBHiE0nFle44gZaQdCVcNNsYGqpUSntITjv5cfCyTH2vGtd1767iHbB/1HQJcoj+Si8QGHw02OUqIYpU60NcpdJARsxcmg76yh4ta5eXln+BP8R34U7Nx0g5Kp5+IAK9HR6q7jHRPleHJmpnwcKxViIjS4vVOYV0QpFSW917chMfjfHupOf7R6N8ZAlkj3jFoKt7ZAqFYUTa3gW7PXrWdI7PmimCz23tZgsJzV1PkCWw0PVtmST4uyU4Dc0c+64YHcSo5wAxFU6hWTNvXceH1l2SDp5izpnE0NwJgyNWqRqIh/Ms2hT+5krLWqnrFs7dAufJl1ZNUejx7MGv9SZbWZbtbZhRqlA=")

        const state = reactive({
            isCopiedLink: false,
            isCopiedCode: false,
            isReferralRewards: false,
            showLoading: false
        })
        const data = reactive({
            // link:websiteHost_+'/share_link?code=werfd2',
            // code: 'JLJD52',
            // shared: 356413,
            // amount: 356413

            // code: route.query.code || "******",
            // code: store.state.share.shareInfo.inviteCode,
            encryptedToken: decodeURIComponent(route.query.id as any ?? ' ').replace(/\s/g, '+').replace(/%2B/g,'+'),
            // encryptedToken: route.query.id as any
            // link: `${websiteHost_}/share_link?code=${store.state.share.shareInfo.inviteCode}`,
            // shared: store.state.share.shareInfo.invitesShareCount,
            // amount: store.state.share.shareInfo.withdrawnPoints
        })

        function back() {
            router.back()
        }

        function createQRCode(link: string) {
            const qrcode = new QRCode('qrcode', {
                width: 142,
                height: 142,
            })
            const updatedLink = link.includes('?') ? `${link}&p=url&lang=${route.query.lang??'cn'}` : `${link}?p=url&lang=${route.query.lang??'cn'}`
            qrcode.makeCode(updatedLink)
        }

        function copyCode() {
            const success = copyToClipboardUsingTextarea(textToCopy.value?.textContent)// Call the utility function
            state.isCopiedCode = true
            copiedCode()
            console.log(success)
        }

        function copyLink() {
            const updatedLink = link.value.includes('?') ? `${link.value}&p=url&lang=${route.query.lang??'cn'}` : `${link.value}?p=url&lang=${route.query.lang??'cn'}`
            const success = copyToClipboardUsingTextarea(updatedLink)
            toggleComponent('isCopiedLink')
            console.log(success)
        }

        function copiedCode() {
            if (state.isCopiedCode && tipsRef.value) {
                tipsRef.value.showTips({
                    show: true,
                    title: local.value.share_25
                })
                return false
            }
        }

        function takeScreenisDone() {
            if (tipsRef.value) {
                tipsRef.value.showTips({
                    show: true,
                    title: local.value.share_26
                })
                return false
            }
        }

        function toggleComponent(name: string) {
            if (name in state) {
                state[name] = !state[name]
            }
        }

        const captureScreenshot = async () => {
            if (screenShotRef.value) {
                // openComponent('showLoading')
                screenShotRef.value.captureScreenshot(shareInfo.value.inviteCode) // Call the method in child
            }
        };

        const closeComponent = (name: string) => {
            if (name in state) {
                state[name] = false
            }
        }

        const openComponent = (name: string) => {
            if (name in state) {
                state[name] = true
            }
        }

        return {
            host,
            back,
            createQRCode,
            copyCode,
            tipsRef,
            textToCopy,
            copyLink,
            state,
            toggleComponent,
            local,
            data,
            captureScreenshot,
            screenShotRef,
            shareInfo,
            closeComponent,
            openComponent,
            takeScreenisDone
        }
    }
}
