
import { onMounted, reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { callApi } from '@/api/api'
import Spinner from '@/components/transitions/Spinner.vue'
import MaskLayer from '@/components/share/MaskLayer.vue'
import moment from 'moment'
import Fade from '@/components/transitions/Fade.vue'

export default {
    name: "WithdrawHistory",
    components: {
        Spinner,
        MaskLayer,
        Fade
    },
    setup() {
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        const cards = computed(() => store.state.share.withdrawnHistory.data || [])
        const namesBtnArr = computed(() => store.state.translation.locale.points_details_btn_arr )
        const namesFilterDate = computed(() => store.state.translation.locale.points_details_filter_arr)
        const withdrawnStatusArr= computed(()=> store.state.translation.locale.withdrawnStatusArr)
        const router = useRouter()
        const route = useRoute()
        const listRef = ref(null);
        const data = reactive({
            encryptedToken: decodeURIComponent(route.query.id as any ?? ' ').replace(/\s/g, '+').replace(/%2B/g,'+'),       
        })

        const state = reactive({
             showLoading: false
        })


        onMounted(async () => {
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang??'cn' })
            openComponent('showLoading')
            if (data.encryptedToken) {
                const res = await callApi('/user/points/withdraw/requests', 'GET', data.encryptedToken as any, route.query.lang+'' || 'cn').finally(() => closeComponent('showLoading'))
                if (res.isSuccess) {
                    await store.dispatch('share/setWithdrawnHistory', res)
                    // console.log(res)
                } else {
                    await store.dispatch('share/setWithdrawnHistory', {
                        message: '',
                        total_records: 0,
                        isSuccess: false,
                        data:[]
                    })

                    router.push(
                        {
                            path: '/error',
                            query: { lang: route.query.lang??'cn' }
                        }
                    )
                }
            } else {
                router.push(
                        {
                            path: '/error',
                            query: { lang: route.query.lang??'cn' }
                        }
                    )
            }
        });

        function toggleComponent(name: string) {
            if (name in state) {
                state[name] = !state[name]
            }
        }

        const BackToMyPoints = () => {
            router.back()
        }

        const closeComponent = (name: string) => {
            if (name in state) {
                state[name] = false
            }
        }

        const openComponent = (name: string) => {
            if (name in state) {
                state[name] = true
            }
        }


        const convertDate = (date) => {
            return moment(date).format("YYYY-MM-DD HH:mm")
        }

        const selectType = (type) => {
            if(type == '处理中') return withdrawnStatusArr.value[0]
            else if(type == '提现成功') return withdrawnStatusArr.value[1]
            else if(type == '提现失败') return withdrawnStatusArr.value[2]
            else if(type == '提现取消') return withdrawnStatusArr.value[3]
        }

       

        return {
            data,
            state,
            toggleComponent,
            listRef,
            local,
            BackToMyPoints,
            closeComponent,
            openComponent,
            convertDate,
            namesBtnArr,
            namesFilterDate,
            cards,
            selectType
        }
    }
}

