<template>
    <!-- 提示弹层 -->
    <transition name="fade-down" appear mode="out-in">
        <div class="tips" v-show="tips.show" :class="[$route.query.lang?.toString().toLowerCase() == 'en'? 'en-tips':'']">
            <div class="succ d-flex align-items-center justify-content-center">
                <i class="bi bi-exclamation cc align-self-start"></i>
                {{ tips.title }}

            </div>
        </div>
    </transition>
</template>
<script>
import { defineComponent, reactive, watch } from 'vue';

export default defineComponent({
    name: 'ErrorTips',
    setup(_, { expose }) {
        // Define reactive state
        const tips = reactive({
            show: false,
            title: '',
        });

        // Automatically hide tips after 3 seconds
        watch(() => tips.show, (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    tips.show = false;
                }, 5000);
            }
        });

        // Expose a method to show the tips
        const showTips = (data) => {
            tips.title = data.title || '';
            tips.show = data.show || false;
        };

        // Expose `showTips` method to be accessed by parent
        expose({ showTips });

        return {
            tips,
        };
    },
});
</script>
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap');
.tips {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    font-family: 'PingFangSC-Medium';
    font-weight: 600 !important;
    width: 100%;
    max-width: 191px;
}

.en-tips {
    max-width: 300px !important;
}

/* .cn-tips {
    max-width: 260px !important;
} */

/* @media (max-width:360px) {
    .tips {
        width: 90%;
        max-width: 320px;
    }
} */

.succ {
    padding-right: 8px;
    font-size: 14px;
    /* background-color: #EDF0F5;
	color: #8E9196; */

    /* color: #4080FC;
	background-color: #dee9fe; */

    /* background-color: #16367c;
	color:#ffffff; */

    /* background-color: #b7ebcf; */
    /* color: #109627; */

    background-color: rgba(253, 46, 46, 1);
    color: white;

    border-radius: 4px;
    box-sizing: border-box;
}


.cc::before {
    color: rgba(253, 46, 46, 1);
    background-color: white;
    border-radius: 100%;
    font-weight: 600 !important;
    padding: 1.5px;

}
.cc {
    display: flex;
    justify-content: center;
}


.fade-down-enter-from {
    opacity: 0;
    transform: translateY(-100%) translateX(-50%);
}

.fade-down-enter-active {
    transition: all 0.5s;
    transform: translateY(-100%) translateX(-50%);
}

.fade-down-enter-to {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
}

.fade-down-leave-from {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
}

.fade-down-leave-active {
    transition: all 0.5s;
    transform:translateY(-100%) translateX(-50%);
}

.fade-down-leave-to {
    opacity: 0;
    transform: translateY(-100%) translateX(-50%);
}
</style>
