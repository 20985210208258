
import { onMounted, reactive, computed, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { callApi, callPostApi } from '@/api/api';
import router from '@/router';
import { getPreviousRoute } from '@/router'
import MaskLayer from '@/components/share/MaskLayer.vue'
import Fade from '@/components/transitions/Fade.vue'
import RedeemDialog from '@/components/points/RedeemDialog.vue'
import MessageRedeemDialog from '@/components/points/MessageRedeemDialog.vue'
import Spinner from '@/components/transitions/Spinner.vue';
import { formatTimePlan_ } from '@/utils/utils'
import ErrorTips from '@/components/transitions/ErrorTips.vue';
import ConfirmRedeemDialog from '@/components/points/ConfirmRedeemDialog.vue';
import SuccessRedeemDialog from '@/components/points/SuccessRedeemDialog.vue';
import FieldRedeemDialog from '@/components/points/FieldRedeemDialog.vue';

export default {
    name: "MyPoints",
    components: {
        MaskLayer,
        Fade,
        RedeemDialog,
        MessageRedeemDialog,
        Spinner,
        ErrorTips,
        ConfirmRedeemDialog,
        SuccessRedeemDialog,
        FieldRedeemDialog
    },

    setup() {

        type TipsInstance = {
            showTips: (data: { show: boolean; title: string }) => void;
        };

        const store = useStore()
        const route = useRoute()
        // const encryptedToken = ref("erfwS4j1Gby5u8IkDklubG02UafmRtfsKFkfl/wFTiB3mDTl0WmsWYWyc0Do2J8iCJUAbgpAlpTVWBHiE0nFle44gZaQdCVcNNsYGqpUSntITjv5cfCyTH2vGtd1767iHbB/1HQJcoj+Si8QGHw02OUqIYpU60NcpdJARsxcmg76yh4ta5eXln+BP8R34U7Nx0g5Kp5+IAK9HR6q7jHRPleHJmpnwcKxViIjS4vVOYV0QpFSW917chMfjfHupOf7R6N8ZAlkj3jFoKt7ZAqFYUTa3gW7PXrWdI7PmimCz23tZgsJzV1PkCWw0PVtmST4uyU4Dc0c+64YHcSo5wAxFU6hWTNvXceH1l2SDp5izpnE0NwJgyNWqRqIh/Ms2hT+5krLWqnrFs7dAufJl1ZNUejx7MGv9SZbWZbtbZhRqlA=")
        const local = computed(() => store.state.translation.locale)
        const packages = computed(() => store.state.share.systemPlans)
        // const shareInfo = ref({...store.state.share.shareInfo})
        const availableSharPoints = computed(() => store.state.share.shareInfo.availableSharePoints)
        const availableCashablePoints = computed(() => store.state.share.shareInfo.availablePurchasePoints)
        const pendingPoints = computed(() => store.state.share.shareInfo.pendingPurchasePoints)
        const amount = computed(() => store.state.share.shareInfo.userAvailablePurchasePointsNoRefundNoPending)
        const share_gifted_points = computed(() => store.state.share.shareInfo.share_gifted_points)
        const shareRebate = computed(() => store.state.share.shareInfo.share_rebate)
        const errorRef = ref<TipsInstance | null>(null);

        onMounted(async () => {
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang ?? 'cn' })
            if ((getPreviousRoute() as any) !== '/points_details') {
                openComponent('showLoading')
                // console.log("token=== ", data.encryptedToken)
                await callPointsAPI()
                let resPackages = await callApi('/systemplans/lists?redeem_list=1', 'GET', data.encryptedToken,route.query.lang+'' || 'cn' ).finally(() => closeComponent('showLoading'))
                if (resPackages.isSuccess) {
                    await store.dispatch('share/setSystemPlans', resPackages.data)
                    // console.log(resPackages.data)
                } else {
                    await store.dispatch('share/setSystemPlans', [])
                }
            }
        });

        const data = reactive({
            selectedPackage: {},
            messageRedeem: '',
            titleRedeem: '',
            encryptedToken: decodeURIComponent(route.query.id as any ?? ' ').replace(/\s/g, '+').replace(/%2B/g,'+'),
            confirmedUsername: ''
        })


        const state = reactive({
            showRedeem: false,
            showMessageRedeem: false,
            showLoading: false,
            showConfirm: false,
            showSuccess: false,
            showField: false
        })

        const goToShare = () => {
            router.push({ path: '/share', query: { ...route.query, id: data.encryptedToken ?? '' } })
        }

        const goToPointsDetails = () => {
            router.push({ path: '/points_details', query: { ...route.query, id: data.encryptedToken ?? '', state_points:'all' } })
        }

        const goToPendingPoints = () => {
            router.push({ path: '/points_details', query: { ...route.query, state_points: 'pending', } })
        }

        const goToEarnMony = () => {
            router.push({ path: '/points_details', query: { ...route.query, state_points: 'rebat', } })
        }

        const goToWithdraw = () => {
            router.push({ path: '/withdrawal', query: { ...route.query , id: data.encryptedToken ?? ''  } })
        }

        const selectPackage = (item: any) => {
            openComponent('showRedeem')
            data.selectedPackage = item
            // console.log(item)
        }

        const closeComponent = (name: string) => {
            if (name in state) {
                state[name] = false
            }
        }

        const openComponent = (name: string) => {
            if (name in state) {
                state[name] = true
            }
        }

        const callPointsAPI = async () => {
            let resData = await callApi(`/user/points`, 'GET', data.encryptedToken)
            if (resData.isSuccess) {
                await store.dispatch('share/setShareInfo', resData)
                // console.log(resData)
            } else {
                await store.dispatch('share/setShareInfo', {
                    status: null,
                    message: '',
                    inviteCode: '******',
                    invitesShareCount: 0,
                    availableSharePoints: 0,
                    availablePurchasePoints: 0,
                    pendingPurchasePoints: 0,
                    availablePurchasePointsYuan: 0,
                    redeemedTrafficPackages: [],
                    yesterdayPoints: 0,
                    withdrawnPoints: 0,
                    isSuccess: false,
                    share_rebate: [{ new_purchase_percent: 0, renew_percent: 0 }, { new_purchase_percent: 0, renew_percent: 0 }, { new_purchase_percent: 0, renew_percent: 0 }],
                    user_name: '',
                    withdrawnYuan: 0,
                    userAvailablePurchasePointsNoRefundNoPending: 0,
                    userAvailablePurchasePointsNoRefundNoPendingYuan:0,
                    share_gifted_points:0
                })
                router.push(
                    {
                        path: '/error',
                        state: { message: resData }
                    }
                )
            }
        }

        const redeemPackage = async (msg, username, item) => {

            if (msg) {
                showTips(msg)
            } else {
                // let body = {
                //     plan_id: item.id,
                //     lang: "EN",
                //     user_name: username,
                //     confirm: false
                // }
                closeComponent('showRedeem')
                openComponent('showLoading')
                let resRedeem = await callRedeemPackage(item.id,"EN",username,false)
                console.log(resRedeem)
                // let resRedeem = await callPostApi('/user/points/redeem/package', 'POST', JSON.stringify(body), data.encryptedToken).finally(() => {
                //     closeComponent('showLoading')
                // })
                if (resRedeem.isSuccess) {
                    // the user exsists and open confirm dialog and call points api
                    data.confirmedUsername = username
                    openComponent('showConfirm')

                    // data.titleRedeem = local.value.my_points_18
                    // data.messageRedeem = local.value.my_points_19

                    // openComponent('showMessageRedeem')
                    // await callPointsAPI()
                } else {
                    // the user not exists and open redeem dialog again (keep-alive) and show error user not exsists
                    openComponent('showRedeem')
                    showTips(local.value.my_points_33)
                    // data.titleRedeem = local.value.my_points_20
                    // data.messageRedeem = local.value.my_points_21
                }


            }
        }


        const formatTimePlan = (obj, days, hours, minutes) => {
            return formatTimePlan_(obj, days, hours, minutes)
        }

        const showTips = (msg) => {
            if (msg && errorRef.value) {
                errorRef.value.showTips({
                    show: true,
                    title: msg
                })
                return false
            }
        }

        const callRedeemPackage = async (item_id, lang, username, config) => {
            let body = {
                plan_id: item_id,
                lang: lang,
                user_name: username,
                confirm: config
            }
            let res =  await callPostApi('/user/points/redeem/package', 'POST', JSON.stringify(body), data.encryptedToken)
            .finally(() => {
                closeComponent('showLoading');
            })

            return res
        }

        const confirmRedeem = async (item) => {
            closeComponent('showConfirm')
            openComponent('showLoading')
            let resRedeem = await callRedeemPackage(item.id,"EN",data.confirmedUsername,true)
                if (resRedeem.isSuccess) {
                    openComponent('showSuccess')
                    await callPointsAPI()
                } else {
                    openComponent('showField')
                }
        }

        const showErrorMessage = (msg) => {
           if(msg) showTips(msg)
        }

        return {
            ...toRefs(data),
            local,
            goToShare,
            goToPointsDetails,
            goToPendingPoints,
            selectPackage,
            ...toRefs(state),
            closeComponent,
            openComponent,
            redeemPackage,
            availableSharPoints,
            availableCashablePoints,
            pendingPoints,
            amount,
            packages,
            formatTimePlan,
            shareRebate,
            errorRef,
            confirmRedeem,
            showErrorMessage,
            goToEarnMony,
            goToWithdraw,
            share_gifted_points
        }
    }
}

