
import html2canvas from 'html2canvas'
import { reactive } from 'vue'
import { downloadFile } from '@/utils/utils'

export default {
    name: "ScreenShot",
    props: {
        code: String
    },
    setup() {

        const data = reactive({
            fixedRoute: '',
            screenshotUrl: '',
            // st: new Date(),
            // et: new Date()
        })

        const captureScreenshot = async (code) => {
            try {
                // data.st = new Date();
                data.fixedRoute = `/share_link?code=${code}&p=image`;

                const iframe = document.querySelector('iframe') as HTMLIFrameElement;
                const previousContent = iframe.contentDocument?.querySelector('.container-mobile');

                if (previousContent) {
                    await processScreenshot(previousContent);
                } else {
                    iframe.src = '';
                    iframe.src = data.fixedRoute;

                    iframe.onload = async () => {
                        const iframeContent = iframe.contentDocument?.querySelector('.container-mobile');
                        if (iframeContent) {
                            await processScreenshot(iframeContent);
                        }
                    };
                }
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        };

        const processScreenshot = async (iframeContent) => {
            const unwantedElements = iframeContent.querySelectorAll('.header, .footer, .url-bar');
            unwantedElements.forEach((el) => (el as HTMLElement).style.display = 'none');

            const canvas = await html2canvas(iframeContent as HTMLElement, {
                logging: false,
                useCORS: true,
                scale: 1.5,
            });

            unwantedElements.forEach((el) => (el as HTMLElement).style.display = 'block');

            data.screenshotUrl = canvas.toDataURL('image/png');
            shareScreenshot();
        };

        const shareScreenshot = async () => {
            if (!data.screenshotUrl) return;

            try {
                const file = new File([dataURLtoBlob(data.screenshotUrl)], 'share-page.png', { type: 'image/png' });

                downloadFile(file);

                // data.et = new Date();
                // console.log('Execution Time:', (data.et.getTime() - data.st.getTime()) / 1000);
            } catch (error) {
                console.error('Error sharing screenshot:', error);
            }
        };

        const dataURLtoBlob = (dataurl: string): Blob => {
            const arr = dataurl.split(',');
            const mime = arr[0].match(/:(.*?);/)![1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new Blob([u8arr], { type: mime });
        };


        return {
            captureScreenshot,
            data
        }

    }
}
