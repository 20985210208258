<template>
    <div class="spinner-border text-light my-spinner" role="status" >
                <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
    name:"Spinner",
}
</script>

<style scoped>
    .my-spinner {
        position: fixed;
        z-index: 110;
        left: calc(50% - 22.625px);
        top: calc(50% - 22.625px);
    }
</style>