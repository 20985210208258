import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-133c2616"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "redeem-dialog pb-0" }
const _hoisted_2 = { class: "title text-black fw-semibold" }
const _hoisted_3 = { class: "des mb-2" }
const _hoisted_4 = { class: "orange-color" }
const _hoisted_5 = { class: "orange-color" }
const _hoisted_6 = { class: "input-group mb-3 mx-auto py-2" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "group-s-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString($setup.local.my_points_34), 1),
    _createElementVNode("p", _hoisted_3, [
      _createTextVNode(_toDisplayString($setup.local.my_points_23) + " ", 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString($setup.props.package.price_coins) + _toDisplayString($setup.local.my_points_24), 1),
      _createTextVNode(" " + _toDisplayString($setup.local.my_points_28) + " ", 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString($setup.formatTimePlan($setup.props.package.plan_time_obj, $setup.local.my_points_14, $setup.local.my_points_16, $setup.local.my_points_17)) + " " + _toDisplayString($setup.props.package.bandwidth_limit) + "GB ", 1),
      _createTextVNode(" " + _toDisplayString($setup.local.my_points_27), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString($setup.local.my_points_29) + "    " + _toDisplayString($setup.props.username), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "cancel-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.hide_ && $setup.hide_(...args)))
      }, _toDisplayString($setup.local.my_points_26), 1),
      _createElementVNode("button", {
        class: "confirm-btn active-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
      }, _toDisplayString($setup.local.my_points_35), 1)
    ])
  ]))
}