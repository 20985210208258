


let apiHostUrl = 'https://jc.juejueziapi.com/api'
let websiteHost = 'https://juechenjsq.com' 
//console.log("process.env ", process.env)
//console.log("window.location.href",window.location.href)

if(process.env.NODE_ENV=='production'){
    // console.log('is production 1')
    apiHostUrl = 'https://jc.juejueziapi.com/api'
    websiteHost = 'https://juechenjsq.com'
}
else 
if(process.env.NODE_ENV=='development'){
    // console.log('is development 1')
    apiHostUrl = 'http://107.149.163.8:8080/api'
    websiteHost = 'http://107.149.163.8:8000' 
}
//http://192.168.0.131
if(window.location.href.indexOf("localhost") >= 0 || window.location.href.indexOf("192.168.0.111") >= 0 || window.location.href.indexOf("192.168.0.222") >= 0) {
    // console.log('is localhost')
    apiHostUrl= 'http://107.149.163.8:8080/api'
    websiteHost = 'http://107.149.163.8:8000' 

}

/* else if(window.location.href.indexOf("dev.") >= 0){
    console.log('is development 2')
    apiHostUrl = 'http://107.149.163.8:8080/api'
    websiteHost = 'http://107.149.163.8:8000' 
}
} */

else if(window.location.href.indexOf("107.149.163.8") >= 0){
   // apiHostUrl = 'http://107.149.163.8:4000/website' // on test server withou nginx
   //console.log('is development 3')
   apiHostUrl = 'http://107.149.163.8:8080/api' // with nginx redirect from port 80 to port 4000
   websiteHost = 'http://107.149.163.8:8000' 
}
 else{
     // production
    //  console.log('is production 2')
    apiHostUrl = 'https://jc.juejueziapi.com/api'
    websiteHost = "https://juechenjsq.com"
}

//console.log("apiHostUrl", apiHostUrl)

// const websiteHost = "https://juechenjsq.com"
// const websiteHost = "http://192.168.222:8080"
// const websiteHost = "http://107.149.163.8:8000" 

export const apiHost = apiHostUrl

export  const downloadServerLink = "https://sanjie.huyuedown.net/"

export const apiUploadUrl = apiHostUrl + '/upload/ckeditor'

export const websiteHost_ = websiteHost