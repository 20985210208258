import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/mobile/fi-rr-angle-small-right.svg'


const _hoisted_1 = { class: "container-mobile bg-points-details overflow-auto" }
const _hoisted_2 = { class: "content content-points-details" }
const _hoisted_3 = { class: "header d-flex text-black p-3 custom-fs-18 container-390 position-fixed" }
const _hoisted_4 = { class: "mx-auto fw-medium" }
const _hoisted_5 = { class: "content-all-points px-3" }
const _hoisted_6 = { class: "card-list-content mt-3 pb-3" }
const _hoisted_7 = { class: "block" }
const _hoisted_8 = { class: "type-card text-black e1 mb-1" }
const _hoisted_9 = { class: "date-card e2" }
const _hoisted_10 = { class: "block text-end" }
const _hoisted_11 = { class: "points-card text-black e1 mb-1" }
const _hoisted_12 = { class: "state-card e2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mask_layer = _resolveComponent("mask-layer")!
  const _component_Fade = _resolveComponent("Fade")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            role: "button",
            src: _imports_0,
            alt: "back",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.BackToMyPoints()))
          }),
          _createElementVNode("div", _hoisted_4, _toDisplayString($setup.local.withdrawn_history_1), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", { class: "empty-packages mt-3" }, _toDisplayString($setup.local.points_details_4), 513), [
            [_vShow, $setup.cards.length == 0]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cards, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "item border-0 mb-3 d-flex justify-content-between p-3 bg-white rounded-2"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString($setup.local.withdrawn_history_2), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString($setup.convertDate(item.updated_at)), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, "￥" + _toDisplayString(item.yuans), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(item.withdraw_status_name), 1)
                ])
              ]))
            }), 128))
          ], 512), [
            [_vShow, $setup.cards.length > 0]
          ])
        ])
      ]),
      _createVNode(_component_Fade, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_mask_layer, null, null, 512), [
            [_vShow, $setup.state.showLoading]
          ])
        ]),
        _: 1
      })
    ]),
    _withDirectives(_createVNode(_component_Spinner, null, null, 512), [
      [_vShow, $setup.state.showLoading]
    ])
  ], 64))
}