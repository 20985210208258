export const getChannelName = function () {
console.log("getChannelName=====", window.location.host)
    switch (window.location.host) {
        case 'em.juechenjsq.com':
            return 'email'; 
        case 'tf1.juechentf.net':  
            return 'tf1'; 
        case 'tf2.juechenjsq.com':
            return 'tf2'; 
        case 'tf3.juechentf.net':
            return 'tf3'; 
        case 'tf4.juechentf.net':
            return 'tf4';
        case 'tf5.juechentf.net':
            return 'tf5';
        case 'tf6.juechentf.net':
            return 'tf6'; 
        case 'tf7.juechentf.net':
            return 'tf7'; 
        case 'tf8.juechentf.net':
            return 'tf8';
        case 'juechenjsq.com':			
            return 'jc'; 
        case 'localhost:8081':
            return 'email';
        case 'test.juechenjsq.com': 
            return '360'; 
             
        default:
            return "jc";
    }
}

export const getTranslationByChannelName = function () {
    console.log("getTranslationByChannelName=====", window.location.host)
        switch (window.location.host) {
            case 'juechenjsq.com':			
                return 'translation_jc'; 
            case 'localhost:8081':
                return 'translation_jc';
            case 'test.juechenjsq.com': 
                return 'translation_360'; 
                 
            default:
                return "translation_jc";
        }
    }

export const getChannelNameScriptSRC = function () {
    console.log("getChannelName=====", window.location.host)
        switch (window.location.host) {
            case 'em.juechenjsq.com':
                return 'https://hm.baidu.com/hm.js?409ebfab297253911dd4e884fd30c396'; 
            case 'tf1.juechentf.net':  
                return 'https://hm.baidu.com/hm.js?0e2c60445173d682ea5557a9309e1dd0'; 
            case 'tf2.juechenjsq.com':
                return 'https://hm.baidu.com/hm.js?0e6d92707d5834639cb9732fc7b50509'; 
            case 'tf3.juechentf.net':
                return 'https://hm.baidu.com/hm.js?63df3cd45fcfb90bd835b98af32d3b31'; 
            case 'tf4.juechentf.net':
                return 'https://hm.baidu.com/hm.js?3b55231a47177bff0f9652ab08d43b24';
            case 'tf5.juechentf.net':
                return 'https://hm.baidu.com/hm.js?55cfe1a06bc4a11440dbb0ab2b64a2f2'; 
            case 'tf6.juechentf.net':
                return 'https://hm.baidu.com/hm.js?608151929498f012830bd46c587ad17c';
            case 'juechenjsq.com':			
                return 'https://hm.baidu.com/hm.js?409ebfab297253911dd4e884fd30c396'; 
            case 'localhost:8081':
                return 'https://hm.baidu.com/hm.js?409ebfab297253911dd4e884fd30c396';
            case 'test.juechenjsq.com': 
                return 'https://hm.baidu.com/hm.js?409ebfab297253911dd4e884fd30c396'; 
                 
            default:
                return "https://hm.baidu.com/hm.js?409ebfab297253911dd4e884fd30c396";
        }
    }
