
import { onMounted, reactive, computed, ref, onUpdated } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { callApi, callPostApi } from '@/api/api'
import Spinner from '@/components/transitions/Spinner.vue'
import MaskLayer from '@/components/share/MaskLayer.vue'
import moment from 'moment'
import Fade from '@/components/transitions/Fade.vue'
import ChangeAccountDialog from '@/components/points/ChangeAccountDialog.vue'
import { nextTick } from 'process'
import ConfirmDataDialog from './ConfirmDataDialog.vue'
import ErrorTips from '@/components/transitions/ErrorTips.vue'

export default {
    name: "Withdrawal",
    components: {
        Spinner,
        MaskLayer,
        Fade,
        ChangeAccountDialog,
        ConfirmDataDialog,
        ErrorTips
    },
    setup() {
        type TipsInstance = {
            showTips: (data: { show: boolean; title: string }) => void;
        };
        const textarea1 = ref<HTMLTextAreaElement | null>(null);
        const textarea2 = ref<HTMLTextAreaElement | null>(null);
        const textarea3 = ref<HTMLTextAreaElement | null>(null);
        const errorRef = ref<TipsInstance | null>(null);
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        const availableCashablePoints = computed(() => store.state.share.shareInfo.availablePurchasePoints)
        const availablePurchasePointsYuan = computed(() => Math.trunc(store.state.share.shareInfo.availablePurchasePointsYuan))
        const withdrawnYuan = computed(() => Math.trunc(store.state.share.shareInfo.withdrawnYuan))
        const router = useRouter()
        const route = useRoute()
        const isEN = computed(() => route.query.lang?.toString().toLowerCase() == 'en')
        const data = reactive({
            encryptedToken:decodeURIComponent(route.query.id as any ?? ' ').replace(/\s/g, '+').replace(/%2B/g,'+'),
            cashInput: '',
            alipayAccount: '',
            realName: '',
            usdtAddress: '',
            errorMessageInputCash: '',
            transferParam: 100
        })

        const state = reactive({
            showLoading: false,
            isSubmitActive: false,
            isAliPay: true,
            showChangeDialog: false,
            validInputCash: false,
            showConfirmDialog: false,
            isfocusTextArea: false
        })
        onUpdated(() => {
            if ((state.validInputCash && data.alipayAccount && data.realName) || (state.validInputCash && data.usdtAddress)) {
                state.isSubmitActive = true
            } else {
                state.isSubmitActive = false
            }
        })

        onMounted(async () => {
            autoResize(textarea1);
            autoResize(textarea2);
            autoResize(textarea3);
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang ?? 'cn' })
            openComponent('showLoading')
            await callPointsAPI()
        });

        const BackToMyPoints = () => {
            router.back()
        }

        const closeComponent = (name: string) => {
            if (name in state) {
                state[name] = false
            }
        }

        const openComponent = (name: string) => {
            if (name in state) {
                state[name] = true
            }
        }

        const convertDate = (date) => {
            return moment(date).format("YYYY-MM-DD HH:mm")
        }

        const changeMethodType = (method) => {
            if (method == 'alipay') {
                state.isAliPay = true
            } else if (method == 'usdt') {
                state.isAliPay = false
            }
            closeComponent('showChangeDialog')
        }

        const isNumber = (amount) => {
            const regEx = /^[0-9]*$/;
            if (!regEx.test(amount)) {
                data.cashInput = data.cashInput.replace(/\D/g, "");
                data.errorMessageInputCash = local.value.withdraw_25
                state.validInputCash = false
            }
             else if (amount < data.transferParam) {
                data.errorMessageInputCash = local.value.withdraw_27
                state.validInputCash = false
            }else if (amount > availablePurchasePointsYuan.value) {
                data.errorMessageInputCash = local.value.withdraw_26
                state.validInputCash = false
            } else {
                data.errorMessageInputCash = ''
                state.validInputCash = true
            }
        }

        const autoResize = (val) => {
            nextTick(() => {
                const element = val;
                if (element && element.scrollHeight) {
                    element.style.height = "27px"; // Reset to min height
                    element.style.height = element.scrollHeight + "px";
                }
            })
        }

        const goToWithdrawnHistory = () => {
            router.push({ path: '/withdrawn-history', query: { ...route.query } })
        }

        const callPointsAPI = async () => {
            let resData = await callApi(`/user/points`, 'GET', data.encryptedToken,  route.query.lang+'' || 'cn').finally(() => closeComponent('showLoading'))
            if (resData.isSuccess) {
                await store.dispatch('share/setShareInfo', resData)
                // console.log(resData)
            } else {
                await store.dispatch('share/setShareInfo', {
                    status: null,
                    message: '',
                    inviteCode: '******',
                    invitesShareCount: 0,
                    availableSharePoints: 0,
                    availablePurchasePoints: 0,
                    pendingPurchasePoints: 0,
                    availablePurchasePointsYuan: 0,
                    redeemedTrafficPackages: [],
                    yesterdayPoints: 0,
                    withdrawnPoints: 0,
                    isSuccess: false,
                    share_rebate: [{ new_purchase_percent: 0, renew_percent: 0 }, { new_purchase_percent: 0, renew_percent: 0 }, { new_purchase_percent: 0, renew_percent: 0 }],
                    user_name: '',
                    withdrawnYuan: 0,
                    userAvailablePurchasePointsNoRefundNoPending: 0,
                    userAvailablePurchasePointsNoRefundNoPendingYuan: 0,
                    share_gifted_points:0
                })
                router.push(
                    {
                        path: '/error',
                        state: { message: resData }
                    }
                )
            }
        }

        const submitRequest = async () => {
            let body = {
                full_name: data.realName,
                bank_account: data.alipayAccount,
                address: data.usdtAddress,
                payment_method: state.isAliPay? 'alipay' : 'usdt',
                yuans: data.cashInput || 0,
                lang: route.query.lang+'' || 'cn'
            }
            openComponent('showLoading')
            let res = await callPostApi('/user/points/withdraw/request', 'POST', JSON.stringify(body), data.encryptedToken)
                .finally(() => {
                    closeComponent('showLoading');
                })
            if(res.isSuccess) {
                router.push(
                {
                    path: '/withdraw-success',
                    query: {...route.query}
                }
            )
            } else {
                showTips(res.message)
            }
            
        }

        const textareaIsFocus = () => {
            state.isfocusTextArea = !state.isfocusTextArea
        }

        const showTips = (msg) => {
            if (msg && errorRef.value) {
                errorRef.value.showTips({
                    show: true,
                    title: msg
                })
                return false
            }
        }


        // const validAllFields = () => {
        //     if((state.validInputCash && data.alipayAccount && data.realName) || (state.validInputCash && data.usdtAddress)) {
        //         state.isSubmitActive = true
        //     } else {
        //         state.isSubmitActive = true
        //     }
        // }

        return {
            data,
            state,
            local,
            BackToMyPoints,
            closeComponent,
            openComponent,
            convertDate,
            isEN,
            changeMethodType,
            availableCashablePoints,
            availablePurchasePointsYuan,
            withdrawnYuan,
            // validateInputCash,
            isNumber,
            autoResize,
            textarea1,
            textarea2,
            textarea3,
            goToWithdrawnHistory,
            submitRequest,
            textareaIsFocus,
            errorRef
            // validAllFields
        }
    }
}

