
import { useStore } from 'vuex'
import { computed } from 'vue';
import { formatTimePlan_ } from '@/utils/utils';

export default {
	emits: ['hide','confirm'],
	name: 'ConfirmRedeemDialog',
	props: ["package","username"],
	setup(props, {emit}) {
		const store = useStore()
		const local = computed(() => store.state.translation.locale)
		const formatTimePlan = (obj, days, hours, minutes) => {
			return formatTimePlan_(obj, days, hours, minutes)
		}


		const hide_ = () => {
			emit('hide')
		}

		return {
			local,
			props,
			formatTimePlan,
			hide_
		}
	}
}
