
import * as CryptoJS from "crypto-js";

export function checkAccessSinglePage(action: string, authUser: any){
    try{
        if(authUser.role_id == 1){
            return true
        }
        const findIndex = authUser.rolesArr.findIndex(obj => obj.permission_key === action)
        if(findIndex == 0 || (findIndex && findIndex!='-1')){
            return true
        }else{
            return false
        }
    }catch(err){
        return false
    }
}

export function generateSlug(str: string){
    try{
        return str
        .trim()                      // remove whitespaces at the start and end of string
        .toLowerCase()              
        .replace(/^-+/g, "")         // remove one or more dash at the start of the string
        .replace(/[^\w-]+/g, "-")    // convert any on-alphanumeric character to a dash
        .replace(/-+/g, "-")         // convert consecutive dashes to singuar one
        .replace(/-+$/g, "")
    }catch(err){
        console.log("err")
    }
}
export function validURL(str: string){
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  // Capitalize the first letter of each word in a string
export function capitalizeFirstOfEveryWord(str) {
    const arr = str.split(" ");
    //loop through each element of the array and capitalize the first letter.
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
  
    let result = ''
    for (let i = 0; i < arr.length; i++) {
      result = result +' '+ arr[i];
    }
  
    return result
  }

  
export function encrypt(src) {
    const passphrase = "ALAA573";
    return CryptoJS.AES.encrypt(src, passphrase).toString();
  }
  
  export function decrypt(src) {
    const passphrase = "ALAA573";
    const bytes = CryptoJS.AES.decrypt(src, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
 
  
  export function toUtcDateTime(d){
    return new Date(d).toISOString().replace('T', ' ').split(".")[0]
  }
  

  export function copyToClipboardUsingTextarea(text) {
    // Create a hidden textarea element
    const textarea = document.createElement('textarea')
  
    // Set the value of the textarea to the text you want to copy
    textarea.value = text
  
    // Ensure the textarea is not visible
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px' // Position off-screen to keep it hidden
    document.body.appendChild(textarea) // Append it to the body
  
    // Select the text in the textarea
    textarea.select()
    textarea.setSelectionRange(0, textarea.value.length) // For mobile devices
  
    // Try to copy the selected text to the clipboard
    try {
      const successful = document.execCommand('copy') // This will copy the text
      document.body.removeChild(textarea) // Clean up by removing the textarea
      return successful // Return whether the copy was successful
    } catch (err) {
      document.body.removeChild(textarea) // Clean up if there was an error
      console.error('Failed to copy text:', err)
      return false
    }
    }

 export function downloadFile(file: File) {
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name; // The name of the file to download
      document.body.appendChild(a);
      a.click(); // Programmatically click the link to trigger download
      document.body.removeChild(a); // Remove the link after download
      URL.revokeObjectURL(url); // Clean up the URL object
}

  
export function formatTimePlan_(obj,days,hours,minutes) {
  let str = ''
  if(obj?.days>0){
      str+=`${obj?.days}${days}`
      if(obj?.hours>0) {
          str+=`, ${obj?.hours}${hours}`
          if(obj?.minutes>0){
              str+=`, ${obj?.minutes}${minutes}`
              return str
          }else{
              return str
          }
      }else{
          if(obj?.minutes>0){
              str+=`, ${obj?.minutes}${minutes}`
              return str
          }else{
              return str
          }
      }
  }else {
      if(obj?.hours>0) {
          str+=`${obj?.hours}${hours}`
          if(obj.minutes>0){
              str+=`, ${obj?.minutes}${minutes}`
              return str
          }else{
              return str
          }
      }else{
          if(obj?.minutes>0){
              str+=`${obj?.minutes}${minutes}`
              return str
          }else{
              return str
          }
      }
  }
} 