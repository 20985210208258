<template>

    <!-- <img class="mb-3 justify-content-center image" src="@/assets/images/mobile/error-image.png" alt="error-image"> -->
    <div class="d-flex flex-column">
        <div class="image1 mb-3"> </div>
        <div class="title text-black mb-3 text-center">{{ local.error_share_1 }}</div>
        <div class="des mb-3 text-center">{{ local.error_share_2 }}</div>
        <button class="go-back-btn rounded-4 mx-auto text-center" @click="goHome()">{{ local.error_share_3 }}</button>
    </div>
</template>

<script>


import { onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
    name: 'Error',
    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        onMounted(async () => {
            await store.dispatch(`translation/getLangs`, { userLang: route.query.lang })
        })

        const goHome = () =>{
            router.push(
                {path: '/'}
            )
        }

        return {
            local,
            goHome
        };

        
    }
}
</script>

<style scoped>
.image1 {
   background-image: url('../../assets/images/mobile/error-image.png');
   background-size: contain;
   background-repeat: no-repeat;
   height: 300px;
   background-position: center ;
}

.title {
    font-size: 36px;
}

.des {
    color: rgba(136, 136, 136, 1);
    font-size: 16px;
}

.go-back-btn {
    color: rgba(64, 128, 252, 1);
    background-color: rgba(64, 128, 252, 0.1);
    border: 1px solid rgba(64, 128, 252, 1);
    width: 100px;
    margin-bottom: 200px;
}


</style>