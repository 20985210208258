<template>
	<div class="dialog-share-notice">
		<p class="title">{{ local.dialog_share_tips_1 }}</p>
		<p class="des">{{ local.dialog_share_tips_2 }}</p>
		<button class="btn btn-know"
						@click="$emit('hide')">{{ local.dialog_share_tips_3 }}</button>
	</div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue';
export default {
    name:'DialogShareTips',
    setup() {
        const store = useStore()
        const local = computed(() => store.state.translation.locale)
        return {
            local
        }
    }
}
</script>

<style scoped>
.dialog-share-notice {
    position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;
	width: 80%;
    max-width: 360px;
	text-align: center;
	background: #ffffff;
    border-radius: 5px;
    padding: 16px 0;
}
	

	.title {
        /* margin-top: 16px; */
		font-family: 'PingFangSC-Medium';
		color: #1E3351;
        font-size: 20px;
    }
		

	.des {
        padding: 0 16px;
		font-size: 16px;
		border-bottom: 1px solid #F6F8FA;
		color: #70757A;
        padding-bottom: 10px;
    }
		
	.btn {
        display: block;
		width: 100%;
		line-height: 8px;
        font-size: 20px;
		color: #4080FC;
        outline: none !important;
        border: none !important;
    }
		
</style>